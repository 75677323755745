
 
//require('./js/force');  

require('./js/daily-nav'); 
require('./js/marsreader');
     
require('./js/blog-draft-2021');  
require('./js/blog-draft-may21');  
require('./js/blog-draft-2020mar');  
require('./js/blog-draft-2020mar');  
require('./js/blog-draft-may19');
require('./js/blog-draft-march19');
require('./js/blog-draft-jan19');
// require('./js/blog-draft-dec');
// require('./js/blog-draft-nov');
// require('./js/blog-draft-oct');
// require('./js/blog-draft-sep');
// require('./js/blog-draft-aug');
// require('./js/blog-draft-jul');
// require('./js/blog-draft-june');
// require('./js/blog-draft-may');
// require('./js/blog-draft-apr');
// require('./js/blog-draft-mar');
// require('./js/blog-draft-feb'); 
    